import React,{useEffect }from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import BoxApi from '../components/BoxApi';
//import BoxServiceRequestsV2 from '../components/BoxServiceRequestsv2';
//import BoxServiceRequests from '../components/BoxServiceRequests';
import BoxDailyDataAmount from '../components/BoxDailyDataAmount';
import BoxAlarms from '../components/BoxAlarms';
import BoxDevices from '../components/BoxDevices';
import BoxSmartMeters from '../components/BoxSmartMeters';

const Home = () => {
  const { isLoggedIn } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if(isLoggedIn){
    } 
  }, [dispatch,isLoggedIn]);
  
  if (!isLoggedIn) {
    return <Redirect push to="/login" />;
  }

  return (
      <Box style={{ margin:'20px', height: '100%', width: '100%' }}>
       <BoxDevices/>
       <BoxSmartMeters/>
       <BoxAlarms/>
       <BoxDailyDataAmount/>
       <BoxApi/>
       </Box>

  );
};

export default Home;
