import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Paper, Box, Slider, Tab, Tabs } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { clearMessage } from "../actions/message";
import { clearData } from "../actions/data";
import { getData } from "../actions/data";
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Bar } from 'react-chartjs-2';
import { indigo, lime, green, orange, blue } from '@material-ui/core/colors';
import { TabPanel } from './TabPanel';



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '90%',
      height: '90%',

    }
  },
  title: {
    marginLeft: 10
  },
  slider: {
    padding: '20px'
  }
}));

function getColor(type) {
  switch (type) {
    case 'night-flow':
      return orange[400]
    case 'night-flow-low-service':
      return lime[400]
    case 'high-flow-client':
      return blue[600]
    case 'smart-meter-leak':
      return green[900]
    default:
      return indigo[900]
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BoxAlarms = (props) => {
  const classes = useStyles();
  const { message } = useSelector(state => state.message);
  const { alarmData } = useSelector(state => state.data);
  const { alarmDataLoaded } = useSelector(state => state.data);
  const dispatch = useDispatch();
  const [time, setTime] = React.useState(30);
  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(clearData({ type: 'alarms' }));
    dispatch(getData({ type: 'alarms', time: 60 }));
  }, [dispatch]);

  let labels = []
  let activeTotalStacks = {}
  let activeTotalDatasets = []
  let activedStacks = {}
  let activedDatasets = []
  let _types = ['night-flow', 'night-flow-low-service', 'high-flow-client', 'smart-meter-leak', 'digitsfail', 'no-period-data']
  //let _internalTypes = ['digitsfail', 'no-period-data']

  if (alarmDataLoaded) {
    _types.forEach(type => {
      activeTotalStacks[type] = {
        stack: "total",
        label: type,
        data: [],
        backgroundColor: getColor(type)
      }
      activedStacks[type] = {
        stack: "changed",
        label: type,
        data: [],
        backgroundColor: getColor(type)
      }
    });

    alarmData.forEach(e => {
      _types.forEach(type => {
        activeTotalStacks[type].data.push(e.activeTotal[type])
        activedStacks[type].data.push(e.actived[type])
      })
      labels.push(e.date)
    });

    for (let path in activeTotalStacks) {
      activeTotalDatasets.push(activeTotalStacks[path])
    }

    for (let path in activedStacks) {
      activedDatasets.push(activedStacks[path])
    }

  }

  const data = {
    labels,
    datasets: activeTotalDatasets
  }

  const activeddata = {
    labels,
    datasets: activedDatasets
  }

  const marks = [
    { value: 7, label: '7 d' },
    { value: 30, label: '1 month' },
    { value: 60, label: '2 months' },
    { value: 90, label: '3 months' },
    { value: 180, label: '6 months' },
    { value: 365, label: 'year' }]

  const handleTimeChange = (event, value) => {
    setTime(value);
    dispatch(getData({ type: 'alarms', time: value }));
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper>
        <center><Typography variant="h6" className={classes.title}>Alarms</Typography></center>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="tabs">
          <Tab label="Active" />
          <Tab label="Activated" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Typography variant="h6" className={classes.title}>
            {time < 60 ? "Active Alarms" : "Active Alarm Averages"}
          </Typography>
          <Box className={classes.slider}>
            <Slider
              valueLabelDisplay="auto"
              value={time}
              step={null}
              onChange={handleTimeChange}
              aria-labelledby="continuous-slider"
              max={365}
              marks={marks} />
          </Box>
          {!alarmDataLoaded && <LinearProgress />}
          {data && <Bar
            data={data}
            options={{
              maintainAspectRatio: true,
              scales: {
                xAxes: [{
                  stacked: true
                }],
                yAxes: [{
                  stacked: true
                }]
              }
            }}
          />}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Typography variant="h6" className={classes.title}>
            Alarms activated
      </Typography>
          <Box className={classes.slider}>
            <Slider
              valueLabelDisplay="auto"
              value={time}
              step={null}
              onChange={handleTimeChange}
              aria-labelledby="continuous-slider"
              max={365}
              marks={marks} />
          </Box>
          {!alarmDataLoaded && <LinearProgress />}
          {data && <Bar
            data={activeddata}
            options={{
              maintainAspectRatio: true,
              scales: {
                xAxes: [{
                  stacked: true
                }],
                yAxes: [{
                  stacked: true
                }]
              }
            }}
          />}
        </TabPanel>
        {message && <Alert severity="error">{message}</Alert>}
      </Paper>
    </div>
  );
};

export default BoxAlarms;
