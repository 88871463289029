import React, { useState, useRef, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';

import {Avatar, Button,TextField} from '@material-ui/core';
import {Typography} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';

import { login } from "../actions/auth";
import { clearMessage } from "../actions/message";
import config from "../helpers/config"
//import { google } from '@material-ui/icons/';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  glogin: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = (props) => {
  const classes = useStyles();
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  const dispatch = useDispatch();
  const token = props && props.match && props.match.params && props.match.params.token ? props.match.params.token : null

  useEffect(() => {
      dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    if(token){
     console.log("run login with token")
     dispatch(login({token}))
    }
}, [dispatch, token]);

  const handleLogin = (e) => {
    e.preventDefault();

    dispatch(login({username, password}))
  };

  if (isLoggedIn) {
    return <Redirect push to="/home" />;
  }

  return (
    <div className={classes.paper}>
    <Typography component="h1" variant="h5">
      Log in
    </Typography>
    <Button
        type="submit"
        fullWidth
        variant="contained"
        style={{background: "#00a2ed", color:"white"}}
        className={classes.submit}
        ref={checkBtn}
        onClick={() => {window.location.href = config.API_SERVER_URL + "/auth/google?redirect=/login/callback/&failureRedirect=login/error"}}>
        Log in with Google
    </Button>

    <Typography component="h1" variant="h5">
    <Avatar className={classes.avatar}>
      OR
    </Avatar>
    </Typography>

    <Button
        type="submit"
        fullWidth
        variant="contained"
        style={{background: "#dc4e41", color:"white"}}
        className={classes.submit}
        ref={checkBtn}
        onClick={() => {window.location.href = config.API_SERVER_URL + "/auth/msauth?redirect=/login/callback/&failureRedirect=/auth/error/"}}>
        Log in with microsoft
    </Button>

    <Typography component="h1" variant="h5">
    <Avatar className={classes.avatar}>
      OR
    </Avatar>
    </Typography>


    <form className={classes.form} onSubmit={handleLogin} ref={form}>
      <TextField
        onChange={(e)=>setUserName(e.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="username"
        label="Username"
        autoComplete="username"
      />
      <TextField
        onChange={(e)=>setPassword(e.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="password"
        label="password"
        type="password"
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        ref={checkBtn}>
        Log In
      </Button>


      {message && <Alert severity="error">{message}</Alert>}
    </form>
  </div>
  );
};

export default Login;
