import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Paper, Box, Slider, Tab, Tabs, FormControlLabel, Checkbox } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { clearMessage } from "../actions/message";
import { clearData } from "../actions/data";
import { getData } from "../actions/data";
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Bar } from 'react-chartjs-2';
import { TabPanel } from './TabPanel';
import { getColor, adjustColor } from '../helpers/utils';
const _ = require('lodash');


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '90%',
      height: '90%',

    }
  },
  title: {
    marginLeft: 10
  },
  slider: {
    padding: '20px'
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BoxDevices = (props) => {
  const classes = useStyles();
  const { message } = useSelector(state => state.message);
  const { devicesData } = useSelector(state => state.data);
  const { devicesDataLoaded } = useSelector(state => state.data);
  const dispatch = useDispatch();
  const [time, setTime] = React.useState(60);
  const [tabValue, setTabValue] = React.useState(0);
  const [selectedCountries, setSelectedCountries] = React.useState({})

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(clearData({ type: 'devices' }));
    dispatch(getData({ type: 'devices', time: 60 }));
  }, [dispatch]);

  useEffect(() => {
    console.log("Selection change")
  }, [selectedCountries]);

  let labels = []
  let devicesTotalStacks = {}
  let devicesTotalDataSets = []
  let devicesChangesStacks = {}
  let devicesChangesDataSets = []

  let countryTotalActiveStacks = {}
  let countryTotalDeliveredStacks = {}
  let countryChangedDeliveredStacks = {}
  let countryChangedActiveStacks = {}
  let countryTotalDataSets = []
  let countryChangedDataSets = []
  let countryLabels = []

  let _allcountries = []
  let _selectedcountries = []
  let _states = []

  if (devicesDataLoaded) {

    devicesData.forEach(e => {
      for (const key in e.countries.total) {
        if (selectedCountries[key]) {
          _selectedcountries.push(key)
        }
        _allcountries.push(key)
      }
      for (const key in e.sum.total) {
        _states.push(key)
      }
    })

    _states = _.sortBy(_.uniq(_states))
    _allcountries = _.sortBy(_.uniq(_allcountries))
    _selectedcountries = _.sortBy(_.uniq(_selectedcountries))

    _states.forEach(type => {
      devicesTotalStacks[type] = {
        stack: "total",
        label: type,
        data: [],
        backgroundColor: getColor(type)
      }
      devicesChangesStacks[type] = {
        stack: "changed",
        label: type,
        data: [],
        backgroundColor: getColor(type)
      }
    });

    devicesData.forEach(e => {
      _states.forEach(type => {
        devicesTotalStacks[type].data.push(e.sum.total[type] || 0)
        devicesChangesStacks[type].data.push(e.sum.changes[type] || 0)
      })
      labels.push(e.date)
    });

    for (let path in devicesTotalStacks) {
      devicesTotalDataSets.push(devicesTotalStacks[path])
    }

    for (let path in devicesChangesStacks) {
      devicesChangesDataSets.push(devicesChangesStacks[path])
    }

    //Countries
    if (_selectedcountries.length) {
      countryTotalActiveStacks = {}

      _selectedcountries.forEach(ci => {
        countryTotalActiveStacks[ci] = {
          stack: "active",
          label: ci + "(active)",
          data: [],
          backgroundColor: getColor(ci)
        }
        countryTotalDeliveredStacks[ci] = {
          stack: "delivered",
          label: ci + "(delivered)",
          data: [],
          backgroundColor: adjustColor(getColor(ci), -40)
        }

        countryChangedDeliveredStacks[ci] = {
          stack: "changed_delivered",
          label: ci + "(delivered)",
          data: [],
          backgroundColor: adjustColor(getColor(ci),-40)
        }
        countryChangedActiveStacks[ci] = {
          stack: "changed_active",
          label: ci + "(active)",
          data: [],
          backgroundColor: getColor(ci)
        }
      });

      devicesData.forEach(e => {
        _selectedcountries.forEach(c => {
          countryTotalActiveStacks[c].data.push(_.get(e, `countries.total[${c}].active`, 0))
          countryTotalDeliveredStacks[c].data.push(_.get(e, `countries.total[${c}].delivered`, 0))
          countryChangedActiveStacks[c].data.push(_.get(e, `countries.changes[${c}].active`, 0))
          countryChangedDeliveredStacks[c].data.push(_.get(e, `countries.changes[${c}].delivered`, 0))
        })
        countryLabels.push(e.date)
      });
    }

    for (let c in countryTotalActiveStacks) {
      countryTotalDataSets.push(countryTotalActiveStacks[c])
      countryTotalDataSets.push(countryTotalDeliveredStacks[c])
    }

    for (let c in countryChangedActiveStacks) {
      countryChangedDataSets.push(countryChangedActiveStacks[c])
      countryChangedDataSets.push(countryChangedDeliveredStacks[c])
    }

  }

  const stateTotalData = {
    labels,
    datasets: devicesTotalDataSets
  }

  const stateChangesData = {
    labels,
    datasets: devicesChangesDataSets
  }

  const byCountriesTotalData = {
    labels: countryLabels,
    datasets: countryTotalDataSets
  }
  const byCountriesChangedData = {
    labels: countryLabels,
    datasets: countryChangedDataSets
  }

  const marks = [
    { value: 7, label: '7d' },
    { value: 30, label: '30d' },
    { value: 60, label: '60d' },
    { value: 90, label: '90d' },
    { value: 180, label: '6 months' },
    { value: 365, label: 'year' },
    { value: 730, label: ' 2 year' }]

  const sliderMax = 730

  const handleTimeChange = (event, value) => {
    setTime(value);
    dispatch(getData({ type: 'devices', time: value }));
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCountrySelectChange = (event) => {
    let s = _.cloneDeep(selectedCountries)
    s[event.target.name] = event.target.checked
    setSelectedCountries(s)
  }

  const countrySelect =
    <Box>{
      _allcountries.map(country => {
        return (<FormControlLabel
          control={
            <Checkbox
              key={`c_${country}`}
              checked={selectedCountries[country] || false}
              onChange={(e) => handleCountrySelectChange(e)}
              name={country}
              style={{ color: getColor(country) }}
            />
          }
          label={country}
          key={`f_${country}`}
        />)
      })
    }</Box>

  const timeslider = <Box className={classes.slider}>
    <Slider
      valueLabelDisplay="auto"
      value={time}
      step={null}
      onChange={handleTimeChange}
      aria-labelledby="continuous-slider"
      max={sliderMax}
      marks={marks} />
    </Box>

  return (
    <div className={classes.root}>
      <Paper>
        <center><Typography variant="h6" className={classes.title}>Devices</Typography></center>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="tabs">
          <Tab label="total" />
          <Tab label="changes" />
          <Tab label="total/countries" />
          <Tab label="changes/countries" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Typography variant="h6" className={classes.title}>
            {time < 60 ? "Device States" : "Device States"}
          </Typography>
          {timeslider}
          {!devicesDataLoaded && <LinearProgress />}
          {stateTotalData && <Bar
            data={stateTotalData}
            options={{
              maintainAspectRatio: true,
              scales: {
                xAxes: [{
                  stacked: true
                }],
                yAxes: [{
                  stacked: true
                }]
              }
            }}
          />}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Typography variant="h6" className={classes.title}>
            Device State changes (device set to state)
      </Typography>
          {timeslider}
          {!devicesDataLoaded && <LinearProgress />}
          {stateChangesData && <Bar
            data={stateChangesData}
            options={{
              maintainAspectRatio: true,
              scales: {
                xAxes: [{
                  stacked: true
                }],
                yAxes: [{
                  stacked: true
                }]
              }
            }}
          />}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Typography variant="h6" className={classes.title}>Country device states</Typography>
          {countrySelect}
          {timeslider}
          {!devicesDataLoaded && <LinearProgress />}
          {byCountriesTotalData && <Bar
            data={byCountriesTotalData}
            options={{
              maintainAspectRatio: true,
              scales: {
                xAxes: [{
                  stacked: true
                }],
                yAxes: [{
                  stacked: true
                }]
              }
            }}
          />}
        </TabPanel>
        <TabPanel value={tabValue} index={3}><Typography variant="h6" className={classes.title}>Country device state changes</Typography>
          {countrySelect}
          {timeslider}
          {!devicesDataLoaded && <LinearProgress />}
          {byCountriesChangedData && <Bar
            data={byCountriesChangedData}
            options={{
              maintainAspectRatio: true,
              scales: {
                xAxes: [{
                  stacked: true
                }],
                yAxes: [{
                  stacked: true
                }]
              }
            }}
          />}
        </TabPanel>
        {message && <Alert severity="error">{message}</Alert>}
      </Paper>
    </div>
  );
};

export default BoxDevices;
