import React, {useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Paper, Slider, Box } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { clearMessage } from "../actions/message";
import { clearData } from "../actions/data";
import { getData } from "../actions/data";
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Bar } from 'react-chartjs-2';
import { indigo, red, lime, green, deepPurple, orange, blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '90%',
      height: '90%',
    },
  },
  title: {
    marginLeft: 10
  },
  slider: {
    padding: '20px'
  }
}));

function getColor(path) {
  switch (path) {
    case "/":
      return red[400]
    case "/alarms":
      return lime[400]
    case "/data":
      return blue[600]
    case "/info":
      return green[500]
    case "/savedata":
      return deepPurple[800]
    case "/api-docs":
      return orange[800]
    case "/ext":
        return orange[400]  
    default:
      return indigo[900]
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BoxApi = (props) => {
  const classes = useStyles();
  const { message } = useSelector(state => state.message);
  const { apiData } = useSelector(state => state.data);
  const { apiDataLoaded } = useSelector(state => state.data);
  const [time, setTime] = React.useState(30);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(clearData({ type: 'api' }));
    dispatch(getData({ type: 'api', time: 61 }));
  }, [dispatch]);

  let labels = []
  let stacks = {}
  let datasets = []
  let _paths = ['/alarms', '/api-docs', '/data', '/info', '/savedata', '/ext']

  if (apiData) {

    _paths.forEach(path => {
      stacks[path] = {
        stack: "sum",
        label: path,
        data: [],
        backgroundColor: getColor(path)
      }
    });

    apiData.forEach(e => {
      _paths.forEach(path => {
        stacks[path].data.push(e.paths[path] ? e.paths[path].sum.count : 0)
      })
      labels.push(e.date)
    });

    for (let path in stacks) {
      datasets.push(stacks[path])
    }
  }

  const data = {
    labels,
    datasets
  }

  const marks = [
    { value: 7, label: '7 d' },
    { value: 30, label: '1 month' },
    { value: 60, label: '2 months' },
    { value: 90, label: '3 months' },
    { value: 180, label: '6 months' },
    { value: 365, label: 'year' }]

  const handleTimeChange = (event, value) => {
    setTime(value);
    dispatch(getData({ type: 'api', time: value }));
  };

  return (
    <div className={classes.root}>
      <Paper>
        <center><Typography variant="h6" className={classes.title}>Data API Request Counts</Typography></center>
        <Box className={classes.slider}>
          <Slider
            valueLabelDisplay="auto"
            value={time}
            step={null}
            onChange={handleTimeChange}
            aria-labelledby="continuous-slider"
            max={365}
            marks={marks} />
        </Box>
        {!apiDataLoaded && <LinearProgress />}
        {data && <Bar
          data={data}

          options={{
            maintainAspectRatio: true,
            scales: {
              xAxes: [{
                stacked: true
              }],
              yAxes: [{
                stacked: true
              }]
            }
          }}
        />}
        {message && <Alert severity="error">{message}</Alert>}
      </Paper>
    </div>
  );
};

export default BoxApi;
