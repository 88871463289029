import axios from "axios";
import config from "../helpers/config.js";

//headers: authHeader()
const login = (q) => {
  return axios
    .post(config.API_SERVER_URL + "/login", q)
    .then((response) => {
      localStorage.setItem("user", JSON.stringify(response.data));
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  login,
  logout,
};
