import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../actions/types";

const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null

const initialState = user
  ? { isLoggedIn: true, user } 
  : { isLoggedIn: false, user:null }

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user:null
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false
      };
    default:
      return state;
  }
}
