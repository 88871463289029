export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const GET_API_DATA_SUCCESS = "GET_API_DATA_SUCCESS";
export const GET_API_DATA_FAIL = "GET_API_DATA_FAIL";
export const CLEAR_API_DATA = "CLEAR_API_DATA";

export const GET_SERVICEREQUEST_DATA_SUCCESS = "GET_SERVICEREQUEST_DATA_SUCCESS";
export const GET_SERVICEREQUEST_DATA_FAIL = "GET_SERVICEREQUEST_DATA_FAIL";
export const CLEAR_SERVICEREQUEST_DATA = "CLEAR_SERVICEREQUEST_DATA";

export const GET_SERVICEREQUEST_V2_DATA_SUCCESS = "GET_SERVICEREQUEST_V2_DATA_SUCCESS";
export const GET_SERVICEREQUEST_V2_DATA_FAIL = "GET_SERVICEREQUEST_V2_DATA_FAIL";
export const CLEAR_SERVICEREQUEST_V2_DATA = "CLEAR_SERVICEREQUEST_V2_DATA";

export const GET_DATAAMOUNT_DATA_SUCCESS = "GET_DATAAMOUNT_DATA_SUCCESS";
export const GET_DATAAMOUNT_DATA_FAIL = "GET_DATAAMOUNT_DATA_FAIL";
export const CLEAR_DATAAMOUNT_DATA = "CLEAR_DATAAMOUNT_DATA";

export const GET_ALARMS_DATA_SUCCESS = "GET_ALARMS_DATA_SUCCESS";
export const GET_ALARMS_DATA_FAIL = "GET_ALARMS_DATA_FAIL";
export const CLEAR_ALARMS_DATA = "CLEAR_ALARMS_DATA";

export const GET_DEVICES_DATA_SUCCESS = "GET_DEVICES_DATA_SUCCESS";
export const GET_DEVICES_DATA_FAIL = "GET_DEVICES_DATA_FAIL";
export const CLEAR_DEVICES_DATA = "CLEAR_DEVICES_DATA";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GET_SMARTMETERS_DATA_SUCCESS = "GET_SMARTMETERS_DATA_SUCCESS";
export const GET_SMARTMETERS_DATA_FAIL = "GET_SMARTMETERS_DATA_FAIL";
export const CLEAR_SMARTMETERS_DATA = "CLEAR_SMARTMETERS_DATA";



