
import { indigo, lime, green, orange, blue, deepPurple, purple, red, yellow } from '@material-ui/core/colors';


export function adjustColor(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}


export function getColor(type) {
    switch (type) {
        case 'accepted':
        case 'NLD':
        case "unreadableMeter":
            return orange[600]
        case 'active':
        case 'FIN':
        case "weakNetwork":
            return blue[700]
        case 'assembled':
        case 'DEU':
            return lime[800]
        case 'created':
        case 'CHE':
        case "moistureOther":
            return green[900]
        case "deactivated":
        case 'FRA':
        case "moistureUnSealed":
            return deepPurple[800]
        case "delivered":
        case 'ITA':
        case "brokenDevice":
            return green[500]
        case "destroyed":
        case 'GBR':
        case "noData":
            return red[500]
        case "destroyed_broken":
        case 'KEN':
            return red[800]
        case "refurbished":
        case 'NOR':
        case "moistureBadInstallation":
            return purple[800]
        case "returned":
        case 'PRT':
            return purple[200]
        case "stored_ext":
        case 'USA':
        case "moved":
            return lime[400]
        case 'SWE':
        case "other":
            return yellow[700]
        default:
            return indigo[900]
    }
}
