import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Paper, Slider, Box } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { clearMessage } from "../actions/message";
import { clearData } from "../actions/data";
import { getData } from "../actions/data";
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Bar } from 'react-chartjs-2';
import { indigo, lime,orange, blue } from '@material-ui/core/colors';

/*
 type:"dailyDataAmount",
 timestamp
 date,
 images,
 values,
imageWithValues
*/

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '90%',
      height: '90%',

    }
  },
  title: {
    marginLeft: 10
  },
  slider: {
    padding: '20px'
  }
}));

function getColor(type) {
  switch (type) {
    case "images":
      return orange[400]
    case "values":
      return blue[600]
    case "imageWithValues":
      return lime[600]
    default:
      return indigo[900]
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BoxDailyDataAmount = (props) => {
  const classes = useStyles();
  const { message } = useSelector(state => state.message);
  const { dailyDataAmountData } = useSelector(state => state.data);
  const { dailyDataAmountDataLoaded } = useSelector(state => state.data);
  const dispatch = useDispatch();
  const [time, setTime] = React.useState(30);

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(clearData({ type: 'dailyDataAmount' }));
    dispatch(getData({ type: 'dailyDataAmount', time: 60 }));
  }, [dispatch]);

  let labels = []
  let stacks = {}
  let datasets = []
  let _types = ['images', 'values']

  if (dailyDataAmountDataLoaded) {
    _types.forEach(type => {
      stacks[type] = {
        stack: "tot",
        label: type,
        data: [],
        backgroundColor: getColor(type)
      }
    });

    dailyDataAmountData.forEach(e => {
      _types.forEach(type => {
        stacks[type].data.push(e[type])
      })
      labels.push(e.date)
    });

    for (let path in stacks) {
      datasets.push(stacks[path])
    }
  }

  const data = {
    labels,
    datasets
  }

  const marks = [
    { value: 7, label: '7 d' },
    { value: 30, label: '1 month' },
    { value: 60, label: '2 months' },
    { value: 90, label: '3 months' },
    { value: 180, label: '6 months' },
    { value: 365, label: 'year' }]

  const handleTimeChange = (event, value) => {
    setTime(value);
    dispatch(getData({ type: 'dailyDataAmount', time: value }));
  };

  return (
    <div className={classes.root}>
      <Paper>
        <center><Typography variant="h6" className={classes.title}>Data amount for day</Typography></center>
        <Box className={classes.slider}>
          <Slider
            valueLabelDisplay="auto"
            value={time}
            step={null}
            onChange={handleTimeChange}
            aria-labelledby="continuous-slider"
            max={365}
            marks={marks} />
        </Box>
        {!dailyDataAmountData && <LinearProgress />}
        {data && <Bar
          data={data}
          options={{
            maintainAspectRatio: true,
            scales: {
              xAxes: [{
                stacked: true
              }],
              yAxes: [{
                stacked: true
              }]
            }
          }}
        />}
        {message && <Alert severity="error">{message}</Alert>}
      </Paper>
    </div>
  );
};

export default BoxDailyDataAmount;
