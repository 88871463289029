import {
  GET_API_DATA_SUCCESS, GET_API_DATA_FAIL, CLEAR_API_DATA, SET_MESSAGE,
  GET_SERVICEREQUEST_DATA_SUCCESS, GET_SERVICEREQUEST_DATA_FAIL, CLEAR_SERVICEREQUEST_DATA,
  GET_SERVICEREQUEST_V2_DATA_SUCCESS, GET_SERVICEREQUEST_V2_DATA_FAIL, CLEAR_SERVICEREQUEST_V2_DATA,
  GET_DATAAMOUNT_DATA_SUCCESS, GET_DATAAMOUNT_DATA_FAIL, CLEAR_DATAAMOUNT_DATA,
  GET_ALARMS_DATA_SUCCESS, GET_ALARMS_DATA_FAIL, CLEAR_ALARMS_DATA,
  GET_DEVICES_DATA_SUCCESS, GET_DEVICES_DATA_FAIL, CLEAR_DEVICES_DATA,LOGIN_FAIL,
  GET_SMARTMETERS_DATA_SUCCESS, GET_SMARTMETERS_DATA_FAIL, CLEAR_SMARTMETERS_DATA
} from "../actions/types";

import DataService from "../services/data.service";

export const getData = q => async dispatch => {
  try {
    let data = await DataService.getData(q)
    if (q.type === 'api') {
      dispatch({ type: GET_API_DATA_SUCCESS, payload: { data } });
    } else if (q.type === 'serviceRequests') {
      dispatch({ type: GET_SERVICEREQUEST_DATA_SUCCESS, payload: { data } });
    } else if (q.type === 'serviceRequestsv2') {
      dispatch({ type: GET_SERVICEREQUEST_V2_DATA_SUCCESS, payload: { data } });
    } else if (q.type === 'dailyDataAmount') {
      dispatch({ type: GET_DATAAMOUNT_DATA_SUCCESS, payload: { data } });
    } else if (q.type === 'alarms') {
      dispatch({ type: GET_ALARMS_DATA_SUCCESS, payload: { data } });
    } else if (q.type === 'devices') {
      dispatch({ type: GET_DEVICES_DATA_SUCCESS, payload: { data } });
    } else if (q.type === 'smartMeters') {
      dispatch({ type: GET_SMARTMETERS_DATA_SUCCESS, payload: { data } });
    }
  } catch (error) {
    
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    if (q.type === 'api') {
      dispatch({ type: GET_API_DATA_FAIL });
    } else if (q.type === 'serviceRequests') {
      dispatch({ type: GET_SERVICEREQUEST_DATA_FAIL });
    } else if (q.type === 'serviceRequests') {
      dispatch({ type: GET_SERVICEREQUEST_DATA_FAIL });
    } else if (q.type === 'serviceRequestsv2') {
      dispatch({ type: GET_SERVICEREQUEST_V2_DATA_FAIL });
    } else if (q.type === 'dailyDataAmount') {
      dispatch({ type: GET_DATAAMOUNT_DATA_FAIL });
    } else if (q.type === 'alarms') {
      dispatch({ type: GET_ALARMS_DATA_FAIL });
    } else if (q.type === 'devices') {
      dispatch({ type: GET_DEVICES_DATA_FAIL });
    } else if (q.type === 'smartMeters') {
      dispatch({ type: GET_SMARTMETERS_DATA_FAIL});
    }
    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    //Login error
    if(error.response.status === 401){
      console.log("Error",error.response.status)
      localStorage.removeItem("user");
      dispatch({ type: LOGIN_FAIL });
    }
  }
};

export const clearData = (q) => {
  if (q.type === 'api') {
    return { type: CLEAR_API_DATA }
  } else if (q.type === 'serviceRequests') {
    return { type: CLEAR_SERVICEREQUEST_DATA }
  } else if (q.type === 'serviceRequestsv2') {
    return { type: CLEAR_SERVICEREQUEST_V2_DATA }
  } else if (q.type === 'dailyDataAmount') {
    return { type: CLEAR_DATAAMOUNT_DATA }
  } else if (q.type === 'alarms') {
    return { type: CLEAR_ALARMS_DATA }
  } else if (q.type === 'devices') {
    return { type: CLEAR_DEVICES_DATA }
  }else if (q.type === 'smartMeters') {
    return { type: CLEAR_SMARTMETERS_DATA};
  }
}
