import { GET_API_DATA_SUCCESS, GET_API_DATA_FAIL, CLEAR_API_DATA,
         GET_SERVICEREQUEST_DATA_SUCCESS, GET_SERVICEREQUEST_DATA_FAIL, CLEAR_SERVICEREQUEST_DATA,
         GET_SERVICEREQUEST_V2_DATA_SUCCESS, GET_SERVICEREQUEST_V2_DATA_FAIL, CLEAR_SERVICEREQUEST_V2_DATA,
         GET_DATAAMOUNT_DATA_SUCCESS, GET_DATAAMOUNT_DATA_FAIL, CLEAR_DATAAMOUNT_DATA,
         GET_ALARMS_DATA_SUCCESS,GET_ALARMS_DATA_FAIL,CLEAR_ALARMS_DATA,
         GET_DEVICES_DATA_SUCCESS,GET_DEVICES_DATA_FAIL,CLEAR_DEVICES_DATA,
         GET_SMARTMETERS_DATA_SUCCESS, GET_SMARTMETERS_DATA_FAIL, CLEAR_SMARTMETERS_DATA} from "../actions/types";

const initialState = {
  apiData:[], apiDataLoaded:false,
  serviceRequestData:[], serviceRequestDataLoaded:false,
  dailyDataAmountData:[], dailyDataAmountDataLoaded:false,
  alarmData:[], alarmDataLoaded:false,
  devicesData:[], devicesDataLoaded:false,
  smartMetersData:[], smartMetersDataLoaded:false,
  serviceRequestV2Data:[], serviceRequestV2DataLoaded:false
};

//dailyDataAmount
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_API_DATA_SUCCESS:
      return {...state,apiDataLoaded:true,apiData:payload.data}
    case GET_API_DATA_FAIL:
      return { apiData: [], apiDataLoaded:false};
    case CLEAR_API_DATA:
      return {...state, apiDataLoaded:false, apiData:[] }
    case GET_SERVICEREQUEST_DATA_SUCCESS:
      return {...state,serviceRequestData:payload.data,serviceRequestDataLoaded:true}
    case GET_SERVICEREQUEST_DATA_FAIL:
      return { serviceRequestData: [], apiDataLoaded:false};
    case CLEAR_SERVICEREQUEST_DATA:
      return {...state, serviceRequestData:[], serviceRequestDataLoaded:false }
    case GET_SERVICEREQUEST_V2_DATA_SUCCESS:
      console.log('reduser OK')
      return {...state,serviceRequestV2Data:payload.data,serviceRequestV2DataLoaded:true}
    case GET_SERVICEREQUEST_V2_DATA_FAIL:
      return { serviceRequestV2Data: [], serviceRequestV2DataLoaded:false};
    case CLEAR_SERVICEREQUEST_V2_DATA:
      return {...state, serviceRequestV2Data:[], serviceRequestV2DataLoaded:false }    
    case GET_DATAAMOUNT_DATA_SUCCESS:
      return {...state,dailyDataAmountData:payload.data,dailyDataAmountDataLoaded:true}
    case GET_DATAAMOUNT_DATA_FAIL:
      return { dailyDataAmountData: [], dailyDataAmountDataLoaded:false};
    case CLEAR_DATAAMOUNT_DATA:
      return {...state, dailyDataAmountData:[], dailyDataAmountDataLoaded:false }
    case GET_ALARMS_DATA_SUCCESS:
      return {...state,alarmData:payload.data,alarmDataLoaded:true}
    case GET_ALARMS_DATA_FAIL:
      return { alarmData: [], alarmDataLoaded:false};
    case CLEAR_ALARMS_DATA:
      return {...state, alarmData:[], alarmDataLoaded:false }
    case GET_DEVICES_DATA_SUCCESS:
      return {...state,devicesData:payload.data,devicesDataLoaded:true}
    case GET_DEVICES_DATA_FAIL:
      return { devicesData: [], devicesDataLoaded:false};
    case CLEAR_DEVICES_DATA:
      return {...state, devicesData:[], devicesDataLoaded:false }
      case GET_SMARTMETERS_DATA_SUCCESS:
        return {...state,smartMetersData:payload.data, smartMetersDataLoaded:true}
      case GET_SMARTMETERS_DATA_FAIL:
        return { smartMetersData: [], smartMetersDataLoaded:false};
      case CLEAR_SMARTMETERS_DATA:
        return {...state, smartMetersData:[], smartMetersDataLoaded:false }    
    default:
      return state;
  }
}
