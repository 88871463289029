import axios from "axios";
import authHeader from "./auth-header";
import config from "../helpers/config.js";
const API_URL = config.API_SERVER_URL + '/statistics';

const getData = (q) => {
  let type = q.type || 'api'
  let time = q.time || 14
  return axios
    .get(API_URL + "?type=" + type + '&time=' + time,{ headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

export default {
  getData
};
