import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route} from "react-router-dom";
import Login from "./components/Login";
import HomeView from "./components/Home";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";
import {AppBar,Toolbar,Typography,Button,IconButton,Container, Avatar} from '@material-ui/core';
import {blue, green } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider, makeStyles  } from '@material-ui/core/styles';

const version = require('../package.json').version;
const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: blue[500],//'#0FB2EB',
    },
    contrastThreshold: 3,
    secondary: {
      // This is green.A700 as hex.
      main: green[500] //'#11cb5f',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const App = () => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); 
    });
  }, [dispatch]);

  const logOut = () => {
    dispatch(logout());
    history.push("/login")
  };
 
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
          {/*<Home onClick={()=> history.push("/login")}/>*/}
          </IconButton>
          <Typography variant="h6" className={classes.title}>
          Statistics <span style={{fontSize:12}}>{version}</span>
          </Typography>
          
          {
          !user ? 
          <Button color="inherit"><div onClick={()=> history.push("/login")}>Login</div></Button> : 
          <Button color="inherit"><div onClick={logOut}>Logout </div></Button>
          }
           {user && <Avatar className={classes.small} alt={user.username} src={user.photo}/>}

        </Toolbar>
      </AppBar>
      <Container >
        <Switch>
          <Route exact path="/home" component={HomeView} />
          <Route exact path={["/", "/login", "/login/callback/:token"]} component={Login} />
        </Switch>
      </Container>
      </ThemeProvider>
    </Router>

  );
};

export default App;
